body {
	font-family: 'Noto Sans', sans-serif;
}

#root {
	height: 100%;
}
.content {
	background: #111;
	height: 95%;
}
.App {
	height: 100%;
}
.btn.focus, .btn.active.focus{
	outline: none;
}
.quiz-content {
    min-height: calc(100% - 40px);
}
.quiz--submit-text-button {
	border: 0;
    padding: .01rem;
    background: #fefefe;
    color: #00b19e;
    font-weight: bold;
}
.quiz--submit-text-icon {
	font-size: 20pt !important;
    margin-left: 10pt;
    color: #00b19e;
}
.footer {
	height: 40px;
/*  position: absolute;
  right: 0;
  bottom: auto;
  left: 0;*/
  padding: 0.5rem;
  background-color: #efefef;
  text-align: center;
}
.btn:focus, .btn:active:focus{
	outline: none;
}
*[role='button']:focus{
	outline:none;
}
html, body{
	height: 100%;
	width: 100%;
}
#content{
	height: 100%;
	width: 100%;
	position: relative;
}
#content > div{
	height: 100%;
	width: 100%;
}
.popup-wr{
	position: absolute;
	width: 100%;
	height: 100%;
}
.menu{
	z-index: 1;
	position: absolute;
	width: 400px;
	top: 96px;
	right: 15px;
	margin: 15px 15px 0 15px;
	padding: 0;
}
.toggle-lang-wr{
	text-align: center;
	margin-bottom: 12px;
}
.toggle-lang-wr a{
	color: #23527c;
	text-decoration: underline;
}
.toggle-lang-wr a:hover{
	text-decoration: none;
}
.panel{
	width: 100%;
    overflow-y: auto;
	border-radius: 0;
	border-color: #000;
}
.panel-body{
	overflow-y: auto;
    padding: 15px 0;
    max-width: 600px;
    max-height: 600px;
    margin: auto;
    /*border: 2px solid lightgray ;*/
}

.panel-body-long {
	overflow-y: auto;
    padding-bottom: 16px;
    max-width: 600px;
    margin: auto;
}

.panel-info {
	/*border-color: #5bc0de;*/
}

.location-survey-locButtons {
	margin-bottom: 12pt;
}
.panel-info > .panel-heading {
	background-color: #00b19e;
	/*border-color: #5bc0de;*/
	border-color: #000;
	color: #fff;
}

.location-survey > .panel-heading {
	background-color: #00b19e;
	/*border-color: #5bc0de;*/
	border-color: #000;
	color: #fff;
}

.location-survey-message {
	text-align: center;
	padding: 2pt 20pt;
}


.panel-danger{
	/*border-color: #d9534f;*/
}
.panel-danger > .panel-heading{
	background-color: #ebccd1;
	/*border-color: #d9534f;*/
	border-color: #000;
	color: #000;
}
.panel-heading{
    position: relative;
	border-radius: 0;
}
.panel-title{
	padding: 4px 4px;
	padding-bottom: 6px;
	text-align: center;
	font-weight: bold;
}
.panel-close{
    right: 6px;
    position: absolute;
    top: 8px;
    font-size: 17pt;
}
.intro{
	padding: 0 15px 15px 15px;
}
.start-quiz{
	color: #a94442;
	background-color: #ebccd1;
	border-color: #a94442;
    margin-top: 8pt;
}


.quiz-image-wrapper {
	align-items: center;
	text-align: center;
}

.quiz-input-wrapper  {
	float: right;
    position: absolute;
    margin-left: 250px;
    margin-top: 4px;
}
.quiz-input {
	    border: 2px solid #00b19e !important;
}

.quiz-image {}

div.playerButton {
	margin-right: 8pt;
	margin-left: 8pt;
}

.playerButton {
	display: inline-block;
		min-width: 32pt;
}
.playerWrapper  {

}

.button-explain {
	margin-bottom: 8pt;
	color: #666666;
}

.panel-body .alert{
	display: inline-block;
	margin-bottom: 0;
}
.data-area .panel-body{
	padding-top: 0;
	padding-bottom: 0;
}
.data-wr{
    float: left;
    width:calc(100% - 50px);
	text-align: left;
}
.popup-title-button{
	color: white;
	font-size: 20pt;
	float: right;
	margin-right: 4pt;
}

.popup-head-button {
	color: #fff;
	font-size: 20pt !important;
	float: right;
	margin-right: 4pt;
}

.data-area .quiz-play{
	float: left;
	margin-right: 10px;
	font-size: 40px;
	margin-right: 4pt;
}
.menu--burger-button {
}
.bm-cross-button {
  height: 24pt;
  width: 24pt;
}
.bm-cross {
  background: #fff;
}
.bm-burger-button {
  position: fixed;
  width: 28pt;
  height: 28pt;
  left: 12pt;
  top: 12pt;
}
.bm-item-list {
	background: #00b19e;
	opacity: 0.8;
	height: 98% !important;
	border-top: 1pt solid green;
	border-bottom: 1pt solid green;
}
.bm-burger-bars {

  background: #00b19e;
}
.quiz-record {
	font-size: 30pt !important;
}

.quiz{
	margin-bottom: 0;
}
.quiz .panel-body h4{
    margin-left: 15px;
    margin-right: 15px;
	margin-bottom: 10px;
}

.btn-success {
	background-color: #00b19e !important;
}

.survey-location {
	width: 100%;
	font-size:0.8rem !important;
}
.survey-data{
	padding: 0 15px;
}
.survey-data .formElement{
	margin-bottom: 10px;
}
.mapContainer {
	height: 90%;
	width: 100%;
}

.mapElement {
	height: 100%;
	width: 100%;
}
.quiz-word {
	font-weight: bold;
}
.panel-list {
	padding: 0;
	list-style-type: none;
	margin-top: 8pt;
	margin-bottom: 8pt;
	border-top: 1px solid #dddddd;
}
.data-area .panel-list{
	border-top: none;
	margin: 8pt;
}
.area-data{
	border: 1px solid #ddd;
	border-top: none;
	border-bottom: none;
}
.inner-area-data{
	padding: 0 10px 10px 10px;
	text-align: center;
	margin-top: 10px;
}
.inner-area-data img{
    height: 150px;
}
.panel-quiz {
	background-color: #fff;
    border: 1px solid;
    padding: 8pt;
    max-height: 80vh;
}

.panel-list .list-item{
	position: relative;
}
.panel-list .list-item:hover{
    background-color: #f5f5f5;
    cursor: pointer;
}
.panel-list .list-item:hover label{
    cursor: pointer;
}
.panel-list .list-item:after{
	height: 1px;
	position: absolute;
	bottom: 0;
    left: 0;
    right: 0;
	content: '';
	background: #dddddd;
}
.data-area .panel-list .list-item:last-child:after{
	content: '';
}
.panel-list .list-item .hover{
    padding: 10px 25px;
}
.quiz .panel-list .list-item .hover{
    padding: 0 25px 0 0;
}

.quiz .panel-list .list-item .hover.guided{
	background-color: #eeeeff;
	    border-bottom: 2px dotted #9999ff;
}

.quiz .panel-list .list-item .hover.checked{
	background: #fcf8e3;
}
.panel-list label > *{
	display: inline-block;
	vertical-align: middle;
}
.quiz .panel-list label{
    float: left;
    min-width: 300pt;
    padding: 10px 0 15px 25px;
    margin-bottom: 0;
}
.panel-bottom{
    padding: 0 15px;
}
.prev{
	margin-right: 15px;
	color:#000;
	background-color:#00b19e;
}
.progress{
	width: 200px;
	margin: 8px 0;
	text-align: right;
	padding-right: 7px;
}

.progress-bar{
	background-color:#d9534f !important;
}

.playerWrapper {
	padding:10px;
}

.quiz-play {
	float: right;
	color: #d9534f;
	font-size: 20pt;
}
.quiz .quiz-play{
    font-size: 30pt;
    margin-top:5pt;
}
.tabs-wr{
	margin: 0 15px;
}
.quiz-winner-title{
	margin-bottom: 10px;
}
.quiz-winner {
	font-size: 14px;
    padding: 0 15px;
}
.quiz-winner ul{
	list-style-type: none;
	padding: 0;
}
.quiz-winner li{
	font-weight: bold;
}
.quiz-winner li .glyphicon{
	margin-right: 5px;
	color: #5cb85c;
}

.stars{
	margin-top: 12pt;
	text-align: center;
}
.stars-wr{
	display: inline-block;
}
.your-city{
	margin: 5px auto 0;
}
.your-city input{
	text-align: center;
	margin: 5px 0 10px;
}
.quiz-tnx{
	font-size: 18px;
	margin-bottom: 5px;
	text-align: center;
}
.quiz-comment {
	width: 100%;
}

.area-title-wrapper {
	position: relative;
	float: left;
	width: 80%;
}
.area-date {
	margin-left: 70pt;
}
.accord-ico{
	position: absolute;
	font-size: 18px;
	top: 10px;
	right: 25px;
}

.project-title {
	margin: auto;
	text-align: center;
	padding-top:15px;
	padding-bottom:15px;
	font-size:2rem;
}

.project-title .navbar-toggler {
	display:none;
	margin-left: 10pt;
    float:left;
 }

.project-title a {
	color: #444;
}

.project-title a:hover {
	text-decoration: none;
	color: #444;
}

.project-title--icon {
	margin-right: 8pt;
}

.project-subtitle {
	margin: auto;
	max-width: 580px;
	text-align: justify;
	text-justify: inter-word;
	padding:15px;
}

@media screen and (max-device-height: 800px) {
    .hide-intro{
        display: none;
    }
}

@media screen and (min-device-height: 800px) {
    .show-intro{
        display: none;
    }
}


@media (max-width: 1199px) {
	.menu{
		width: 400px;
	}
}
@media (max-width: 767px) {
	.map--page .project-title {
		visibility: hidden;
		display: none;
	}
	.mapContainer {
		height: 100%;
	}
	.map--page: {
		background-color: red;
	}
	.menu{
        width: calc(100% - 30px);
		top: 0;
		right: 0;
	}
	.project-title {
		font-size:1.2rem;
	}
	.panel-body h4{
		margin: 0 0 10px 0;
		font-size: 16px;
	}
	.panel-body ul{
		margin-top: 0;
	}
	.quiz-variants .list-item{
		padding:9px 0;
	}
	.quiz-variants .list-item label{
		font-size: 12px;
	}
	.quiz .intro{
		overflow-y: auto;
	}
    .hide-intro{
        display: none;
    }
	.stars span{
		font-size: 35px!important;
		margin: 0 5px;
	}
}
@media (min-device-width: 576px) and (orientation: portrait){

}
@media (max-width: 575px) {

	.panel-body h4{
		margin: 0;
	}
	.progress{
		width: 100px;
	}
	.area-date {
		margin-left: 10pt;
	}
}

@media (max-device-width: 767px) and (orientation: landscape) {
	.project-title .navbar-toggler {
		display:block;
		margin-left: 10pt;
    	float:left;
 	}
	.menu{
		display: none;
		margin-top: 10px;
	}
	.language {
		margin-bottom: 10px;
	}
	.quiz.panel.compact{
		width: 205px;
		position: fixed;
		left: 15px;
		top: 55px;
	}
	.quiz.compact .panel-heading{
		border-bottom: none;
	}
	.quiz.compact .panel-close{
		width: 203px;
		height: 35px;
	}
	.quiz.compact .glyphicon-remove:before{
		content: '';
	}
	.quiz.compact .panel-body {
		display: none;
	}
}
footer {
	position: absolute;
	bottom:0;
	left:0;
	right:0;
	height:32px;
	background: #fff;
	border-top: 2px solid #15a48b;
}
footer .container {
	padding-top: 4px;
	text-align:center;
}
.menu__btn {
       margin: 4pt;
       min-width: 90pt;
           color: white;
    font-weight: 600 !important;
    text-decoration: underline;
    font-size: 14pt !important;
}

.menu__btn:hover {
           color: white;
    font-weight: 900;
    text-decoration: underline;
}

.form-error {
	border: 2px dotted red;
	border-radius: 5px;
	padding: 2px;
}

.form-error::after {
	color: red;
	display: block;
	content: " Por favor, rellena este campo";
}

.staticPage__body {
	padding: 4pt;
}

.surveyLocation--result-button {
	border: 0;
    background-color: white;
    padding: .01rem;
}
.surveyLocation--result-first {
		font-size: 17px;
    font-weight: bold;
}

.surveyLocation--result-second {
		font-size: 15px;
    font-weight: bold;
}

.surveyLocation--result-third {
	font-size: 13px;
    font-weight: bold;
}

.menu--main  {
    flex-flow: row nowrap;
    justify-content: flex-start  !important;
}

.menu--list {
	list-style: none;
    padding: 0;
    justify-content: center;
    width: auto;
}

.menu-list a {
	text-decoration: none;
    color: gray;
	font-weight: bold;
    min-width: auto;
}

a {
	color:#5d524b;
	font-weight:600;
}

bg-light {
	background-color:#fcf8e3 !important;
}

.bottomMenu__btn {
	color: #999;
	font-size: 0.8em !important;
	padding-top: 0pt !important;
	padding-bottom: 0pt !important;
	padding-right:.275rem ;
}

.staticPage__content {
	text-align:justify;
}

.intro--icon {
	float:right;
	padding:8px;
}

.uni--logos {
	max-width:600px;
}

.share-title {
	text-align: center;
	font-weight: bold;
	font-size: 16px;
	padding-top: 6px;
}

/* .share-block {
	display: none;
 }
*/
.share-twitter {
	font-size: 44px !important;
	color: #1DA1F2;
}
.share-fb {
	font-size: 44px !important;
	color: #4267b2;
}

.disclaimer-title {
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    padding-top: 6px;

}
.share-block-list {
    padding: 10pt;
    padding-left: 80pt;
    min-width: 100px;
    margin: auto;
    list-style: none;
}

.share-block-list li {
    display: inline;
    padding-left:22pt;
}
